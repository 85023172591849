const parser = new DOMParser();

const fragmentRe = /^\s*<(\w+)[^>]*>/,
      singleTagRe = /^\s*<(\w+)\s*\/?>(?:<\/\1>)?\s*$/;

let containers;

function initContainers () {

  if ( containers ) return;

  const div = document.createElement(`div`),
        table = document.createElement ( `table` ),
        tr = document.createElement ( `tr` );

  containers = {
    '*': div,
    tr: document.createElement ( `tbody` ),
    td: tr,
    th: tr,
    thead: table,
    tbody: table,
    tfoot: table,
  };

}

function parseHTML ( html) {

  initContainers ();

  if ( singleTagRe.test ( html ) ) return document.createElement ( RegExp.$1 );

  const fragment = fragmentRe.test ( html ) && RegExp.$1,
        container = containers[fragment] || containers[`*`];

  container.innerHTML = html;

  return container.childNodes;

}

class Import {
  constructor(el) {
    const url = el.getAttribute(`src`);
    let count = parseInt(el.getAttribute(`data-count`) || 0);

    let {parentNode: parent, innerHTML} = el;
    let element = parseHTML(innerHTML);

    fetch(url)
    .then((response) => response.text())
    .then((html) => parser.parseFromString(html, `text/html`))
    .then((doc) => {
      if (element.tagName === `TABLE`) {
        element = element.querySelector(`tr`);
      }
      return doc.getElementsByTagName(element.tagName);
    })
    .then((items) => {
      if (!count) {
        count = items.length;
      }

      while (count-- && items.length) {
        parent.appendChild(items[0]);
      }

      parent.removeChild(el);
    });
  }
}

export default Import;
