// Lazy load images below the fold.
// Each images should be wrapped in a noscript so they still load.
// The JS will unwrap them, observe for in-view when possible.
const $lazyImages = $(`noscript.lazy-image, [loading="lazy"][data-src]`);
if (`IntersectionObserver` in window) {
  let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
    entries.forEach(function(entry) {
      if (entry.isIntersecting) {
        let lazyImage = entry.target;
        lazyImage.src = lazyImage.getAttribute(`data-src`);
        lazyImage.removeAttribute(`data-src`);
        lazyImageObserver.unobserve(lazyImage);
      }
    });
  });

  $lazyImages.each(function() {
    let $image;

    if (this.tagName.toLowerCase() === "noscript") {
      const $noscript = $(this);
      let html = $noscript.text();
      $image = $(html.replace(` src=`, ` data-src=`));
      $noscript.replaceWith($image);
    } else {
      // Image with `[loading="lazy"]` in a browser with no support but has [data-src] set by MutationObserver
      $image = $(this);
    }

    lazyImageObserver.observe($image[0]);
  });
} else {
  $lazyImages.each(function() {
    const $noscript = $(this);
    $noscript.replaceWith($noscript.text());
  });
}
