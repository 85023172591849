import $ from '../shared/jquery.js';
const MONTHS = [`January`, `February`, `March`, `April`, `May`, `June`,
  `July`, `August`, `September`, `October`, `November`, `December`
];

/**
 * A list that can Filter items based on `select` and `input`
 *
 * Usage:
 * - For each `select` and `input` in the form, make sure they have `name`
 *   attribute, and add class `filter` to them.
 * - For each item, add `data-X` attributes, in which `X` is the `name`
 *   attribute of the `.filter` inputs. The value of these `data-X` attrs
 *   is a children selector, of which text will be used to check against
 *   the `.filter` inputs' value. It can also be a `property` value.
 *   The check is case-insensitive.
 * - Add appropriate class or attribute to the children of the item to
 *   match its selector.
 *
 * Note that the filtering is an AND operation, and is checked against all
 * `.filter` inputs whose value is not empty. For text filter, the filtering
 * happens as user types.
 */
class List {
  constructor(el) {
    const $container = this.$container = $(el);
    const target = $container.attr(`data-target`);
    this.$target = target ? $(target) : $container;
    this.$sort = $container.find(`[name="sort"]`);
    const $perPage = $container.find(`[name="per-page"]`);
    this.perPage = Number($perPage.val());
    this.activePage = 0;
    const $paginator = this.$paginator = $container.find(`.pagination`);

    this.observer = new MutationObserver(this.onMutation.bind(this));

    this.calculate();

    this.$filters.on(`change input`, () => {
      this.paginate(this.$pages[0]);
    });

    $perPage.on(`change`, (event) => {
      const pageEl = event.target;
      const { total, $pages } = this;
      let perPage = this.perPage;
      var page = $pages.filter(`[aria-current="page"]`).index();
      // Figure out the current view contains which rows.
      var rowIndex = page * perPage;

      // Now set `perPage` to new value and `doFilter.
      perPage = this.perPage = Number(pageEl.value);

      // The .paginator has the most number of pages on page load, so we just need
      // hide the extras.
      var pages = Math.floor(total/perPage);
      this.activePage = Math.floor(rowIndex/perPage);

      $pages
      .removeAttr(`aria-current`);

      this.filter();

      $pages.eq(activePage).attr(`aria-current`, `page`);
    });

    $paginator.on(`click`, `[data-page] a`, (event) => {
      event.preventDefault();
      this.paginate(event.target);
    });

    // Other click handlers.
    $paginator.parent()
    .on(`click`, `[rel="prev"]:not([aria-disabled="true"])`, (event) => {
      event.preventDefault();
      const { $pages, activePage } = this;
      this.paginate($pages.eq(activePage - 1)[0]);
    }).on(`click`, `[rel="next"]:not([aria-disabled="true"])`, (event) => {
      event.preventDefault();
      const { $pages, activePage } = this;
      this.paginate($pages.eq(activePage + 1)[0]);
    }).on(`click`, `.first:not([aria-disabled="true"])`, (event) => {
      event.preventDefault();
      this.$pages.first().click();
    }).on(`click`, `.last:not([aria-disabled="true"])`, (event) => {
      event.preventDefault();
      this.$paginator.children().last().click();
    });

    // Initial filter run.
    this.filter();

    this.observe();
  }

  onMutation(mutationList, _observer) {
    // mutationList.forEach(({type, target, addedNodes, removedNodes}) => {

    // });
    const {target} = mutationList[0];

    // We only care about mutations on our list.
    if (!target.isSameNode(this.$list[0])) {
      return;
    }

    this.calculate();
    this.paginate(this.$pages[0]);
  }

  calculate() {
    const $target = this.$target;
    const $filters = this.$filters = this.$container.find(`.filter`);
    var itemSelector = ``;
    // Only apply to items with filter attributes.
    $filters.each(function(index) {
      const $filter = $(this);
      const name = $filter.prop(`name`);
      const options = this.options;

      if (index > 0) itemSelector += `,`;
      itemSelector += `[data-${name}]`;

      // Reset `select` filter with values from new item list.
      if (options) {
        const optionValues = {};
        $filter.find(`option:not([value=""]):not([value="*"])`).remove();
        $target.find(`[data-${name}]`).each(function() {
          const $item = $(this);
          const attr = $item.attr(`data-${name}`);

          // The attribute specifies to match anything, so we don't add it as an option.
          if (attr === `*`) {
            return;
          }

          let $value;

          try {
            $value = $item.find(`${attr}`);
          } catch(e) {}

          if (!$value || !$value.length) {
            $value = $item.find(`[property="${attr}"]`);
          }

          const values = [];
          if ($value.length === 0) {
            if (attr.trim()) {
              // The value is specified directly in the data attribute.
              values.push(attr.trim());
            }
          } else {
            $value.each(function() {
              const value = $(this).text().trim();
              if (value) {
                values.push(value);
              }
            });
          }

          values.sort();

          values.forEach((value) => {
            if (!optionValues[value]) {
              optionValues[value] = true;
              $filter.append(`<option value="${value}">${value}</option>`);
            }
          });
        });

        const sortedOptions = $filter.find(`option:not([value=""]):not([value="*"])`).get().sort((a, b) => {
          a = a.text;
          b = b.text;

          // Sort year descendingly.
          if (/[\d]{4}/.test(a) && /[\d]{4}/.test(b)) {
            if (a > b) return -1;
            if (a < b) return 1;
          }

          if (MONTHS.indexOf(a) > -1 && MONTHS.indexOf(b) > -1) {
            if (MONTHS.indexOf(a) > MONTHS.indexOf(b)) return 1;
            if (MONTHS.indexOf(a) < MONTHS.indexOf(b)) return -1;
          }

          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });

        $filter.append($(sortedOptions));
      }
    });

    let $items = this.$items = itemSelector ? $target.find(itemSelector) : $target.children();
    this.total = $items.length;
    this.$list = $items.parent();

    // Sort
    const sortBy = this.$sort.val();
    if (sortBy) {
      const sortedItems = $items.get().sort(function(a, b) {
        const $a = $(a).find(`data-${sortBy},[property="${sortBy}"]`);
        const $b = $(b).find(`data-${sortBy},[property="${sortBy}"]`);

        if ($a.attr(`datetime`)) {
          a = $a.attr(`datetime`);
          b = $b.attr(`datetime`);
        } else {
          a = $a.text();
          b = $b.text();
        }

        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });

      // Stops the observer so we can sort the nodes.
      this.observer.disconnect();

      $items = this.$items = $(sortedItems);
      this.$list.append($items);

      // Observes for changes again.
      this.observe();

    }

    // Reset pagination.
    const {total, perPage, $paginator} = this;
    if ($paginator.length) {
      let numberOfPages = Math.ceil(total / perPage);
      const $pages = this.$pages = $paginator.find(`[data-page] > a`);
      const $firstPageLink = $pages.first().removeAttr(`aria-current`);
      const $firstPage = $firstPageLink.parent();
      const firstPageIndex = $firstPage.index();
      // Use the first `<li>` HTML as template.
      const template = $firstPage[0].outerHTML;
      let html = ``;
      // Clear out the current pages.
      $pages.parent().remove();

      // Prepare the HTML for all the `<li>`.
      while (numberOfPages--) {
        html = template.replace(/("|#|>|\s)1(?=["<])/g, `$1${numberOfPages + 1}`) + html;
      }

      const $newPages = $(`<ul>${html}</ul>`);
      // When there is no other `<li>` before, we just prepend.
      if (firstPageIndex === 0) {
        $paginator.prepend($newPages.html());
      } else {
        $paginator.children().eq(firstPageIndex - 1).after($newPages.html());
      }
    }

    // Reset the variable.
    this.$pages = $paginator.find(`[data-page] > a`);
  }

  filter(_event) {
    const { $container, $filters, $items, $paginator, $pages, activePage, perPage } = this;

    const filters = {};
    $filters.each(function() {
      const $filter = $(this);
      const name = $filter.prop(`name`);
      const value = this.value;
      if (value) {
        filters[name] = value;
      }
    });

    // Find all the items where their filter selector match the queries
    let $visibles = $items.attr(`hidden`, `hidden`).filter(function() {
      const $item = $(this);
      let matched = true;
      $.each(Object.keys(filters), function(_index, name) {
        const value = filters[name];
        // Early escape.
        if (!matched) return;

        const selector = $item.attr(`data-${name}`);
        if (!selector) {
          matched = false;
          return;
        }

        let $filter;

        try {
          $filter = $item.find(`${selector}`);
        } catch(e) {

        }

        if (!$filter || !$filter.length) {
          $filter = $item.find(`[property="${selector}"]`);
        }

        let filter;
        if ($filter.length === 0) {
          // The filter value is specified directly in the data attribute.
          filter = selector;
        } else {
          filter = $filter.text();
        }

        if (filter.toLowerCase().indexOf(value.toLowerCase()) === -1 && filter !== "*") {
          matched = false;
        }
      });
      return matched;
    });
    const total = this.total = $visibles.length;

    // Display visible filtered items based on `perPage`, which is set before,
    // and the active page.
    if (perPage) {
      $visibles = $visibles.slice(activePage * perPage, (activePage + 1) * perPage);
    }

    $visibles.removeAttr(`hidden`);

    this.$list.attr(`aria-disabled`, !total);

    // Reduce the paginators by simple rules:
    // 1. If at page 1 or 2, display 1,2,3...N
    // 2. If at page 3, display 1,2,3,4...N
    // 3. If at page 4+, display 1,...,3,4,5...N
    const pages = Math.floor(total/perPage);

    // Reset $pages.
    $pages.parent().removeClass(`dotdotdot`);

    $pages
    .hide()
    .filter(function(index, element) {
      return index <= pages;
    }).show();

    if (activePage < 2) {
      $pages.slice(3, pages-1).hide();
    } else {
      // Hide surrounding pages, but page 1, 2, N-1, N
      $pages.slice(2, activePage-1).hide();

      if (activePage < pages-2) {
        $pages.slice(activePage+2, pages-1).hide();
      }
    }

    // Replace the second and next to last items with `...`
    if (activePage >= 3) {
      $pages.eq(1).parent().addClass(`dotdotdot`);
    }
    if (activePage <= pages-3) {
      $pages.eq(pages-1).parent().addClass(`dotdotdot`);
    }

    // Toggle .first, .prev, .next, .last buttons.
    $paginator.find(`.first, [rel="prev"]`).attr(`aria-disabled`, activePage === 0);
    $paginator.find(`[rel="next"], .last`).attr(`aria-disabled`, activePage === pages - 1);

    $container.trigger(`update`, {
      results: $visibles
    });

    this.$visibles = $visibles;
  }

  paginate(pageEl) {
    const $page = $(pageEl);
    this.$pages.removeAttr(`aria-current`);
    this.activePage = this.$pages.index($page);

    this.filter();
    $page.attr(`aria-current`, `page`);
  }

  observe() {
    if (!this.$list.length) return;

    this.observer.observe(this.$list[0], {
      childList: true, // Monitor for any child nodes added or removed.
      attributes: false,
      subtree: true // Also monitor for changes on the child nodes.
    });
  }
}

export default List;
