import "./marathonpetroleum.mjs";
// Or import individual modules.

const tooltips = document.querySelectorAll(`.metrics a.tooltip`);
for (let i = 0, l = tooltips.length; i < l; ++i) {
  const tooltip = tooltips[i];
  const title = tooltip.getAttribute(`title`);
  tooltip.removeAttribute(`title`);
  tooltip.setAttribute(`data-tooltip`, title)
}
