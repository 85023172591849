
import AOS from "../../node_modules/aos/dist/aos.esm.js";

// Animation on scroll.
AOS.init({
  selector: `[data-aos]`,
  animatedClassName: `aos-animate`,
  once: false,
  mirror: true,
  threshold: 0.5
});

window.addEventListener(`load`, function(event) {
  AOS.refresh();
});
