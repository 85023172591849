const CSS = window.CSS || {};
if (!CSS.supports) {
  CSS.supports = function(prop, value) {
    const style = document.createElement(`p`).style;
    style.cssText = `${prop}:${value}`;
    return !!style.length;
  }
}

/* focusin/out event polyfill (firefox) */
!function(){
  var w = window,
  d = w.document;

  if( w.onfocusin === undefined ){
    d.addEventListener('focus' ,addPolyfill ,true);
    d.addEventListener('blur' ,addPolyfill ,true);
    d.addEventListener('focusin' ,removePolyfill ,true);
    d.addEventListener('focusout' ,removePolyfill ,true);
  }
  function addPolyfill(e){
    var type = e.type === 'focus' ? 'focusin' : 'focusout';
    var event = new CustomEvent(type, { bubbles:true, cancelable:false });
    event.c1Generated = true;
    e.target.dispatchEvent( event );
  }
  function removePolyfill(e){
    if(!e.c1Generated){ // focus after focusin, so chrome will the first time trigger tow times focusin
      d.removeEventListener('focus' ,addPolyfill ,true);
      d.removeEventListener('blur' ,addPolyfill ,true);
      d.removeEventListener('focusin' ,removePolyfill ,true);
      d.removeEventListener('focusout' ,removePolyfill ,true);
    }
    setTimeout(function(){
      d.removeEventListener('focusin' ,removePolyfill ,true);
      d.removeEventListener('focusout' ,removePolyfill ,true);
    });
  }
}();

const vendors = [
  `-webkit-`,
  `-o-`,
  `-moz-`,
  `-ms-`,
  ``
];

// Set vendor-prefixed CSS3 styles.
function setCss3Style(style, prop, val) {
  vendors.forEach(function(vendor) {
    const property = `${vendor}${prop}`;
    if(property in style) {
      style[property] = val;
    }
  });
}

const defaultOptions = {
  startIndex: 0, // The initial starting slide index.
  slideSelector: undefined, // Selector for the items.
  viewportSelector: ``, // Selector for the viewport.
  nextSelector: ``, // Selector for next control.
  prevSelector: ``, // Selector for prev control.
  loop: true, // Whether to loop the slides around.
  autoplay: 0, // The interval (in milliseconds) to automatically advance to next slide.
  onInit: undefined, // Callback when carousel is initialized.
  onChange: undefined // Callback when a slide happens.
}

/**
 * A modern responsive carousel implementation.
 *
 * Transition is handled in CSS if CSS Property is supported; otherwise,
 * CSS transform will be set inline by JS.
 *
 * Supports IE9+.
 *
 * @export
 * @class Carousel
 * @extends {Array}
 *
 * @example
 *
 *     new Carousel(el, {
 *       startIndex: 0,
 *       slideSelector: ".carousel__item",
 *       viewportSelector: ".carousel__viewport",
 *       nextSelector: ".carousel__control--next",
 *       prevSelector: ".carousel__control--prev",
 *       loop: true,
 *       onInit: () => {},
 *       onChange: () => {}
 *     });
 */
class Carousel {
  /**
   * Creates an instance of Carousel.
   * @param {Node} el
   * @param {object} [options={}]
   * @memberof Carousel
   */
  constructor(el, options = {}) {
    options = {
      ...defaultOptions,
      ...options
    };

    const startIndex = el.getAttribute(`data-start-index`) || options.startIndex;
    const selector = el.getAttribute(`data-items`) || options.slideSelector;
    const viewportSelector = el.getAttribute(`data-viewport`) || options.viewportSelector;
    const prevSelector = el.getAttribute(`data-prev`) || options.prevSelector;
    const nextSelector = el.getAttribute(`data-next`) || options.nextSelector;
    const autoplay = el.getAttribute(`data-autoplay`) || options.autoplay;

    let items = selector ? el.querySelectorAll(selector) : el.children;
    items = this.items = new Array(...items);

    this._index = startIndex;
    this.viewport = viewportSelector ? el.querySelector(viewportSelector) : el;

    el.addEventListener(`click`, (event) => {
      const target = event.target;

      if (prevSelector && target.matches(prevSelector)) {
        this.index--;
      }

      if (nextSelector && target.matches(nextSelector)) {
        this.index++;
      }
    });

    for (let i=0; i<items.length; i++) {
      const item = items[i];
      item.setAttribute(`aria-hidden`, `true`);
    }

    if (options.onInit) {
      options.onInit.call(this);
    }

    this.el = el;
    this.options = options;

    // Set the initial index.
    this.index = this._index;

    window.addEventListener(`load`, () => {
      this.index = this._index;
    });

    window.addEventListener(`resize`, () => {
      this.index = this._index;
    });

    if (autoplay) {
      options.autoplay = autoplay;
      this._timer = setTimeout(() => this.index++, autoplay);

      // Pause the carousel animation when the mouse pointer is hovering over
      // the carousel or when it receives keyboard focus.

      const suspendAnimation = () => {
        clearTimeout(this._timer);
      }

      const startAnimation = () => {
        clearTimeout(this._timer);
        this._timer = setTimeout(() => this.index++, autoplay);
      }

      // Pausing on mouse hover is useful for people who need more time to read
      // the content and makes it easier to click links in the carousel.
      el.addEventListener(`mouseenter`, suspendAnimation);
      el.addEventListener(`mouseleave`, startAnimation);

      // Keyboard users do not lose their position when the carousel is paused.
      // Note: The focusin and focusout events are defined in the W3C Document
      // Object Model (DOM) Level 3 Events Specification (Working Draft) and
      // implemented in many browsers. Firefox needs a short polyfill at the
      // time of publication of this.
      el.addEventListener(`focusin`, (event) => {
        if (event.target.matches(selector)) {
          suspendAnimation();
        }
      });
      el.addEventListener(`focusout`, (event) => {
        if (event.target.matches(selector)) {
          startAnimation();
        }
      });
    }

    // Transition to matching item by ID specified in hash fragment.
    [`load`, `hashchange`].forEach((type) => {
      window.addEventListener(type, () => {
        const target = el.querySelector(`:target`);
        if (!target) return;

        const targetIndex = this.items.indexOf(target);
        if (targetIndex < 0) return;

        const itemsPerSlide = Math.ceil(items.length / this.slides);
        this.index = targetIndex/itemsPerSlide;
      });
    });
  }

  /**
   * Returns the current index.
   *
   * @memberof Carousel
   */
  get index() {
    return this._index;
  }

  /**
   * Moves the carousel to a specific index.
   *
   * @memberof Carousel
   */
  set index(value = this._index) {
    const {items, el, viewport, options} = this;
    const count = items.length;
    const {onChange, autoplay} = options;
    const loop = el.getAttribute(`data-loop`) === "false"? false : options.loop;

    const rootBounds = viewport.getBoundingClientRect();
    let itemsPerSlide = 0;

    // Calculate number of items per slide based on the current slide layout.
    for (let i = 0; i < count; i++) {
      const item = items[i];
      // @TODO: Find a better way to determine how many items per slide.
      const boundingClientRect = item.getBoundingClientRect();
      const isIntersecting = boundingClientRect.left >= rootBounds.left && boundingClientRect.right <= rootBounds.right;
      if (isIntersecting) {
        itemsPerSlide = Math.round(
          (rootBounds.right - rootBounds.left)
          /
          (boundingClientRect.right - boundingClientRect.left)
        );
      }
      // Mark all items as hidden at first.
      item.setAttribute(`aria-hidden`, `true`);
    }

    const slides = this.slides = Math.ceil(count / itemsPerSlide);

    // Clamp the new index value.
    if (value >= slides) {
      value = loop ? 0 : slides - 1;
    } else if (value < 0) {
      value = loop ? slides - 1 : 0;
    }

    // Mark active items as not hidden.
    for (let i = (value*itemsPerSlide); i < (value+1)*itemsPerSlide; i++) {
      const item = items[i];
      if (item) {
        item.setAttribute(`aria-hidden`, `false`);
      }
    }

    // Set new styles.
    const style = el.style;
    if (CSS.supports(`--slide`, value)) {
      if (!isNaN(slides) && style.getPropertyValue(`--slides`) !== slides) {
        style.setProperty(`--slides`, slides);
      }
      style.setProperty(`--slide`, value);
    } else {
      setCss3Style(viewport.style, `transform`, `translateX(-${100 * value}%)`);
      style[`counter-reset`] = `slide ${value + 1} of ${slides}`;
    }

    if (autoplay) {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => this.index++, autoplay);
    }

    this._index = value;

    if (onChange) {
      onChange.call(this);
    }
  }
}

export default Carousel;
